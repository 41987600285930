import type {StudentInfoAisResponseDto} from "@/global/api/gen/ts/StudentInfoAisResponseDto";
import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {BackLink} from "@/global/components/BackLink";
import {StudentIntegrationCard} from "@/modules/students/components/StudentsByIin/StudentIntegrationCard";

interface Props {
    studentsByIIN: StudentInfoAisResponseDto,
    returnFirstStep: () => void;
    nextStep: () => void;
    navigateToCertificates: () => void,
}

export function StudentsByIinStepClosed({studentsByIIN, navigateToCertificates, returnFirstStep, nextStep}: Props) {
    const haveCertificate = studentsByIIN.students.some((value) => !!value.certificate_number);

    return (
        <>
            <BackLink
                sx={{
                    alignSelf: 'flex-start',
                    fontSize: '18px',
                }}
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
            />
            {
                haveCertificate
                && (
                    <Button
                        variant={'outlined'}
                        onClick={navigateToCertificates}
                    >
                        Скачать свидетельство
                    </Button>
                )}
            <Box
                gap={2}
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(1, minmax(0, 1fr))',
                        md: 'repeat(2, minmax(0, 1fr))',
                        xl: 'repeat(3, minmax(0, 1fr))'
                    },
                }}
            >
                {(studentsByIIN?.students.length > 0 || studentsByIIN.students) && (
                    <>
                        {
                            studentsByIIN.students.map((student, index) => {
                                return (
                                    <StudentIntegrationCard
                                        studentInfo={student}
                                        key={index}
                                    />
                                );
                            })
                        }
                    </>
                )}
                {studentsByIIN.students.length === 0 && (
                    <Typography>
                        По данному ИИН не найдено данных об обучении. Пожалуйста, обратитесь в свою
                        автошколу
                    </Typography>
                )}
            </Box>
            {studentsByIIN.students.length !== 0 && (
                <Button
                    sx={{
                        fontWeight: 'normal',
                        textTransform: 'none',
                        width: {xs: '300px', md: '500px'}
                    }}
                    onClick={nextStep}
                    variant={'contained'}
                >
                    Получить сведения
                </Button>
            )}
        </>
    );
}