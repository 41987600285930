import React, {type ChangeEvent, useEffect, useState} from 'react';
import {Box, CircularProgress, Stack, Typography} from '@mui/material';

import PageHeader from '../../global/components/PageHeader';
import {apiGetStudentsFromAis} from "@/modules/students/services/student.service";
import {toast} from "react-toastify";
import {StudentsByIinStepInput} from "@/modules/students/components/StudentsByIin/StudentsByIinStepInput";
import {StudentsByIinStepClosed} from "@/modules/students/components/StudentsByIin/StudentsByIinStepClosed";
import {
    apiCreateQuickTransaction,
    apiTransactionFreedomWebhook
} from "@/modules/transactions/services/transactions.services";
import {
    StudentsByIinStepStartTransaction
} from "@/modules/students/components/StudentsByIin/StudentsByIinStepStartTransaction";
import {StudentsByIinStepOpened} from "@/modules/students/components/StudentsByIin/StudentsByIinStepOpened";
import {BackLink} from "@/global/components/BackLink";
import {PictureAsPdf} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {BACKEND_URL} from "@/global/actions/apiVariables";
import {useNavigate} from "react-router-dom";

type Steps = 'input' | 'closed' | 'transaction' | 'startTransaction' | 'opened';

function FeePage() {
    const [iin, setIin] = useState<string>('');
    const [agreed, setAgreed] = useState<boolean>(false);
    const [step, setStep] = useState<Steps>('input');
    const [payment, setPayment] = useState(false);
    const [phone, setPhone] = useState<{ phone: string, isValid: boolean }>({phone: '', isValid: false});
    const [email, setEmail] = useState<{ email: string, isValid: boolean }>({email: '', isValid: false});

    const {mutate, data, isPending} = apiCreateQuickTransaction();

    const navigate = useNavigate();

    const {data: studentsByIIN, isLoading: studentsByIINLoading, refetch} = apiGetStudentsFromAis(iin, {
        enabled: false,
        retry: false,
    });
    const {data: dataTransaction} = apiTransactionFreedomWebhook(data?.invoice_id, payment);
    const handleIinChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const sendIin = e.currentTarget.value.replace(/\D/g, '');
        if (sendIin.length > 12) {
            return;
        }
        setIin(sendIin);
    };

    useEffect(() => {
        if (dataTransaction && dataTransaction.status === 2) {
            setPayment(true);
            setStep('opened');
            toast.success('Транзакция проведена');
            void refetch();
            return;
        }
        if (dataTransaction && (dataTransaction.status === 3 || dataTransaction.status === 10)) {
            setStep('input');
            toast.error('Ошибка транзакции. Повторите позже.');
            return;
        }
    }, [dataTransaction]);

    const handleFetchStudents = async () => {
        if (!agreed) {
            toast.error('Необходимо подтвердить согласие лица, в отношении которого ведется поиск учебных данных');
            return;
        }
        if (iin.length !== 12) {
            toast.error('Неверно введен ИИН');
            return;
        }
        const res = await refetch();
        if (res.isError) {
            toast.error('Неизвестная ошибка.');
            return;
        }
        if (res.data && !res.data.obfuscate) {
            setStep('opened');
            return;
        }
        setStep('closed');
    };

    const handleStartTransaction = () => {
        if (!email.isValid) {
            toast.error('Введите электронную почту для отправки данных');
            return;
        }
        if (!phone.isValid) {
            toast.error('Введите номер телефона');
            return;
        }
        mutate({
            amount: 1,
            type: 'payment_student_info',
            payment_system: 'freedom',
            iin,
            extra_data: {
                phone: phone.phone,
                email: email.email,
                iin
            }
        }, {
            onSuccess: () => {
                setStep('transaction');
            }
        });
    };

    const navigateToCertificates = () => {
        navigate(`/certificate/buy?iin=${iin}`);
    };

    const nextStepForClosed = () => {
        setStep('startTransaction');
    };

    const returnFirstStep = () => {
        setStep('input');
        setIin('');
        setPayment(false);
    };

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>

            <Stack
                width={{xs: '90%', lg: '80%'}}
                alignItems={'center'}
                justifyContent={'center'}
                gap={4}
                margin={'0 auto'}
                marginTop={4}
            >
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize: {xs: '30px', md: '37px'}
                    }}
                >
                    Проверка данных об обучении
                </Typography>
                {
                    step === 'input' && (
                        <StudentsByIinStepInput
                            iin={iin}
                            handleIinChange={handleIinChange}
                            agreed={agreed}
                            setAgreed={setAgreed}
                            handleFetchStudents={handleFetchStudents}
                            studentsByIINLoading={studentsByIINLoading}
                        />
                    )
                }
                {
                    step === 'closed' && (
                        studentsByIIN ? (
                                <StudentsByIinStepClosed
                                    navigateToCertificates={navigateToCertificates}
                                    nextStep={nextStepForClosed}
                                    returnFirstStep={returnFirstStep}
                                    studentsByIIN={studentsByIIN}
                                />
                            )
                            :
                            <CircularProgress/>
                    )
                }
                {
                    step === 'startTransaction' && studentsByIIN && (
                        <StudentsByIinStepStartTransaction
                            returnFirstStep={returnFirstStep}
                            isPending={isPending}
                            studentInfo={studentsByIIN.students[0]}
                            handleStartTransaction={handleStartTransaction}
                            setPhone={setPhone}
                            setEmail={setEmail}
                        />
                    )
                }
                {
                    step === 'transaction' && data && (
                        <Stack
                            width={'100%'}
                            gap={4}
                        >
                            <BackLink
                                sx={{
                                    alignSelf: 'flex-start',
                                    fontSize: '18px',
                                }}
                                label={'Вернуться к вводу ИИН'}
                                onClick={returnFirstStep}
                            />
                            <Box
                                flexShrink={0}
                                flexGrow={1}
                                flexBasis={1}
                                sx={{
                                    backgroundColor: 'white',
                                    margin: '0 auto',
                                    borderRadius: '12px',
                                    overflow: 'hidden',
                                    minHeight: '800px',
                                    width: {xs: '100%', md: '60%'}
                                }}
                            >
                                <iframe
                                    width={'100%'}
                                    height={'800px'}
                                    src={data.payment_url ?? ''}
                                    style={{
                                        border: 'none',
                                    }}
                                />
                            </Box>
                        </Stack>
                    )
                }
                {
                    step === 'opened' && (
                        studentsByIIN ? (
                                <>
                                    <StudentsByIinStepOpened
                                        studentsByIIN={studentsByIIN}
                                        returnFirstStep={returnFirstStep}
                                    />
                                    <Stack
                                        gap={2}
                                        sx={{
                                            padding: 4,
                                            borderRadius: '12px',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        <LoadingButton
                                            href={`${BACKEND_URL}/pdf/student-info/${iin}/?transaction_id=${data?.id}`}
                                            target={'_blank'}
                                            variant={'contained'}
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                paddingBlock: 4
                                            }}
                                        >
                                            <PictureAsPdf/>
                                            Скачать
                                        </LoadingButton>
                                        <Typography fontWeight={'lighter'}>
                                            Также ваше свидетельство было отправлено на почту
                                            {' '}
                                            <b>
                                                {studentsByIIN.email}
                                            </b>
                                        </Typography>
                                    </Stack>
                                </>
                            )
                            :
                            <CircularProgress/>
                    )
                }
            </Stack>
        </Box>
    );
}

export default FeePage;
